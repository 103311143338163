import React, { Fragment } from "react"

const OrderSubtotalSection = ({}) => (
  <Fragment>
    <p className="is-size-7 has-text-left px-1 has-text-grey">
      For prescriptions uploaded to the website, total amount will be verified
      by our pharmacist via email. Service fee and coupon code will be applied
      upon checkout.
    </p>
  </Fragment>
)

export default OrderSubtotalSection
