import { authInitialState } from "./authInitialState"

const form = {
  SAVE_DETAILS: "SAVE_DETAILS",
  DISABLE_BUTTON: "DISABLE_BUTTON",
  SET_INCORRECT_BIRTHDAY_ATTEMPT: "SET_INCORRECT_BIRTHDAY_ATTEMPT",
}

const OTP = {
  SET_OTP: "SET_OTP",
  SET_OTP_VERIFIER: "SET_OTP_VERIFIER",
  SET_OTP_SHOW_RESEND_MESSAGE: "SET_OTP_SHOW_RESEND_MESSAGE",
  SET_OTP_RESEND_COUNT: "SET_OTP_RESEND_COUNT",
}

export let AuthReducer = (state, action) => {
  switch (action.type) {
    case form.SAVE_DETAILS:
      return { ...state, ...action.payload }
    case form.DISABLE_BUTTON:
      return { ...state, buttonStatus: action.payload }
    case form.SET_INCORRECT_BIRTHDAY_ATTEMPT:
      return { ...state, incorrectBirthdayAttempt: action.payload }

    case OTP.SET_OTP:
      return { ...state, otp: action.payload }
    case OTP.SET_OTP_VERIFIER:
      return { ...state, otpVerifier: action.payload }
    case OTP.SET_OTP_SHOW_RESEND_MESSAGE:
      return { ...state, otpShowResendMessage: action.payload }
    case OTP.SET_OTP_RESEND_COUNT:
      return { ...state, otpResendCount: action.payload }

    default:
      return { ...authInitialState }
  }
}
