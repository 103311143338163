import React, { useContext } from "react"

import InputCoupon from "./CouponSection/InputCoupon"
import DisplayCoupon from "./CouponSection/DisplayCoupon"

import { AppContext } from "../../context/AppContext"

const CouponSection = () => {
  const { state, dispatch } = useContext(AppContext)
  const { epharmacy } = state
  const { coupon } = epharmacy

  if (coupon)
    return <DisplayCoupon coupon={coupon} dispatch={dispatch} state={state} />

  return <InputCoupon dispatch={dispatch} state={state} />
}

export default CouponSection
