import React, { useContext } from "react"
import { Formik, Form } from "formik"

import MedicineInfo from "./MedicineInfo"
import TotalPriceInfo from "./TotalPriceInfo"
import MedicineQuantityInput from "./MedicineQuantityInput"

import { AppContext } from "../../../context/AppContext"
import { changeQuantity } from "../services/cart"
import { generatePrice } from "../services/computations"

const AddMedicineModal = ({ addToCart, medicine, isEditing, index }) => {
  const { dispatch, state } = useContext(AppContext)
  const { epharmacy } = state
  const { medicines } = epharmacy
  const HIGH_VALUE_MEDS = {
    DEFAULT_QUANTITY: 1,
    MINIMUM_PRICE: 50,
  }

  const handleDeleteMedicine = async (index) => {
    let medicineList = [...medicines]
    medicineList = medicineList.filter(
      (medicine) => medicine.productTitle != index.productTitle
    )

    await dispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: {
        ...state,
        epharmacy: { ...state.epharmacy, medicines: medicineList },
      },
    })
    dispatch({ type: "HIDE_MODAL" })
  }

  const DEFAULT_QUANTITY = 10
  let initialQuantity =
    generatePrice(medicine) > HIGH_VALUE_MEDS.MINIMUM_PRICE
      ? HIGH_VALUE_MEDS.DEFAULT_QUANTITY
      : DEFAULT_QUANTITY

  return (
    <Formik
      initialValues={{ qty: medicine?.qty || initialQuantity }}
      onSubmit={(values) => {
        dispatch({ type: "HIDE_MODAL" })
        if (addToCart) addToCart(medicine, values.qty)
        if (isEditing) isEditing(values.qty, index)
      }}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <div className="mx-4 mx-1-mobile">
            <MedicineInfo medicine={medicine} />

            <MedicineQuantityInput
              changeQuantity={changeQuantity}
              setFieldValue={setFieldValue}
              qty={values.qty}
              initialQuantity={initialQuantity}
              selectedMedicine={medicine}
              value={medicine.qty}
            />
            <hr className="has-background-light my-0" />
            <TotalPriceInfo medicine={medicine} qty={values.qty} />
            <div className="is-flex is-justify-content-center">
              {isEditing && (
                <button
                  type="button"
                  className="button mt-2 mr-1"
                  disabled={medicine.qty < 1}
                  onClick={() => handleDeleteMedicine(index)}
                >
                  Remove
                </button>
              )}
              <button
                type="submit"
                className="button is-primary mt-2 "
                disabled={values.qty < 1}
              >
                {isEditing ? "Update" : "Add to cart"}
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default AddMedicineModal
