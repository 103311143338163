import React from "react"
import classNames from "classnames"
import FormInput from "../../../Elements/Form/FormInput"

const InputCouponCode = ({ selectedCoupon, isLoading, couponCode }) =>
  selectedCoupon === "OTHER" && (
    <FormInput
      hasAddons
      addonRight={
        <button
          className={classNames("button is-primary", {
            "is-loading": isLoading,
          })}
          type="submit"
          disabled={!couponCode}
        >
          Apply
        </button>
      }
      name="couponCode"
      placeholder="ABC123"
      className="is-uppercase"
      hideOptional
      maxLength={25}
      isAlphaNumericOnly
    />
  )

export default InputCouponCode
