export const changeQuantity = (setFieldValue, qty) => {
  if (qty > 0 && qty <= 999) setFieldValue("qty", qty)
  if (qty > 999) setFieldValue("qty", 999)
}

export const isMedicineAlreadyInCart = (medicineToCheck, medicinesList) => {
  const medicineList = [...medicinesList]
  let filteredMedicines = medicineList.filter(
    (medicine) => medicineToCheck.productTitle === medicine.productTitle
  )

  return filteredMedicines.length > 0
}
