import React, { Fragment, useContext } from "react"
import classNames from "classnames"

import DocumentsCardRow from "./DocumentsCard/DocumentsCardRow"
import CollapsibleCard from "../Elements/CollapsibleCard"
import Button from "../Elements/Button"

import { AppContext } from "../../context/AppContext"
import styles from "./utils/cart.module.scss"
import { navigate } from "gatsby"

const DocumentsCard = ({ title, hideButton, filesUploaded }) => {
  const { dispatch, state } = useContext(AppContext)
  const { documents } = state

  const handleDeleteDocument = async (index) => {
    const documentsList = [
      ...documents.filter((doc) => !doc.type?.includes("scpwdId")),
    ]
    const scpwdID = [
      ...documents.filter((doc) => doc.type?.includes("scpwdId")),
    ]
    documentsList.splice(index, 1)
    await dispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: {
        ...state,
        documents: [...documentsList, ...scpwdID],
      },
    })
  }

  const handleRedirectToUpload = () => {
    dispatch(
      {
        type: "HIDE_MODAL",
      },
      navigate("/epharmacy/upload")
    )
    dispatch({
      type: "REMOVE_ACTIVE_DOCUMENT_ONLY",
    })
  }

  return (
    <Fragment>
      <div className={classNames("m-1", styles["cart"])}>
        {filesUploaded
          ?.filter((document) => document?.type?.toLowerCase() === "rx")
          ?.map((file, index) => (
            <DocumentsCardRow
              key={index}
              fileName={file.name}
              oldFileName={file.oldname}
              index={index}
              handleDeleteDocument={handleDeleteDocument}
              file={file}
            />
          ))}
      </div>
      <p className="has-text-left">
        {!hideButton ? (
          <Button
            className="is-text has-text-secondary has-text-weight-bold"
            onClick={handleRedirectToUpload}
          >
            + Add another prescription
          </Button>
        ) : null}
      </p>
      <div className="m-1"></div>
    </Fragment>
  )
}

export default DocumentsCard
