import React, { Fragment } from "react"
import classNames from "classnames"
import { accumulateVat } from "../Epharmacy/services/computations"
import specialCoupons from "../Epharmacy/utils/specialCoupons.json"
import styles from "./utils/cart.module.scss"

import Message from "../Elements/Message"

const CouponDiscountSection = ({ couponDiscount, medicines, coupon }) => {
  const finalCouponDiscount =
    parseFloat(couponDiscount) +
    (specialCoupons.includes(coupon)
      ? parseFloat(accumulateVat(medicines).toFixed(2))
      : 0)

  return (
    <Fragment>
      <hr
        className={classNames("has-background-light", styles["row__divider"])}
      />

      {coupon && (
        <Message className="mb-1" color="success">
          Coupon code {coupon} successfully applied!{" "}
          {couponDiscount > 0 && "Enjoy the discounted price on your order."}
        </Message>
      )}
      <div className="columns is-size-6 is-mobile">
        <div className="column has-text-right">Discount:</div>
        <div className="column pb-0 is-5 has-text-weight-bold has-text-right">
          <span className="mr-1 has-text-danger">
            {finalCouponDiscount > 0 && "-"} Php{" "}
            {finalCouponDiscount.toFixed(2)}
          </span>
        </div>
      </div>
    </Fragment>
  )
}

export default CouponDiscountSection
