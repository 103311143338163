export const initialState = {
  documents: [],
};

const upload = {
  SAVE_DOCUMENTS: "SAVE_DOCUMENTS",
};

export const ProofOfPaymentReducer = (state, action) => {
  switch (action.type) {
    case upload.SAVE_DOCUMENTS:
      return { ...state, documents: [...action.payload] };

    default:
      return { ...state, ...state.initialState }
  }
};
