import React, { createContext, useReducer } from "react"

import { AuthReducer } from "./AuthReducer"
import { authInitialState } from "./authInitialState"

const AuthContext = createContext()

const AuthProvider = (props) => {
  const [authState, authDispatch] = useReducer(AuthReducer, {
    ...authInitialState,
  })

  return (
    <AuthContext.Provider value={{ authState, authDispatch }}>
      {props.children}
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthProvider }
