import React, { Fragment } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTags } from "@fortawesome/free-solid-svg-icons"

import FormSelect from "../../../Elements/Form/FormSelect"
import Message from "../../../Elements/Message"
import Loading from "../../../Elements/Loading"

import couponChoices from "../../utils/couponChoices.json"

const SelectCoupon = ({ isLoading, values, handleSelectedCoupon, setErrors }) =>
  isLoading ? (
    <Loading size={3} />
  ) : (
    <Fragment>
      <FormSelect
        name="selectedCoupon"
        isSearchable={false}
        label={
          <span>
            <span className={`icon has-text-primary`}>
              <FontAwesomeIcon icon={faTags} />
            </span>
            <span className="has-text-primary has-text-weight-bold">
              Discount
            </span>
          </span>
        }
        helper="Select SCPWD below for Senior Citizen/PWD discounts"
        helperClassName="has-text-grey"
        options={couponChoices}
        value={values.selectedCoupon}
        placeholder="Select coupon"
        onChange={(value) => handleSelectedCoupon(value, setErrors)}
        hideOptional
      />
      {values.selectedCoupon?.value === "SCPWD" && (
        <Message>
          Please be informed that as per FDA regulations, SCPWD discount can
          only be applied for a maximum of a month’s worth of prescription drugs
          and seven day’s worth of over-the-counter drugs.
        </Message>
      )}
    </Fragment>
  )

export default SelectCoupon
