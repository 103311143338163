import React from "react"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPrescription } from "@fortawesome/free-solid-svg-icons"

import styles from "./utils/elements.module.scss"

const RxIndicator = () => {
  return (
    <div
      className={classNames(
        "has-text-black has-background-warning has-text-secondary is-size-6 has-text-centered tooltip is-tooltip-bottom",
        styles["rxIndicator"]
      )}
      data-tooltip="Prescription Needed"
    >
      <FontAwesomeIcon icon={faPrescription} />
    </div>
  )
}

export default RxIndicator
