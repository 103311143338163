import React from "react"
import { ApolloProvider } from "react-apollo"
import { client } from "./src/services/apollo"

import "./static/styles/global.scss"
import "./static/styles/tailwind.css"
import { AppProvider } from "./src/context/AppContext"
import { getFirebase } from "services/firebase/firebase"
import { ProofOfPaymentProvider } from "./src/components/ProofOfPayment/ProofOfPaymentContext/ProofOfPaymentContext"
import { AuthProvider } from "./src/context/AuthContext"

export const wrapRootElement = ({ element }) => {
  getFirebase()

  return (
    <AppProvider>
      <AuthProvider>
        <ProofOfPaymentProvider>
          <ApolloProvider client={client}>{element}</ApolloProvider>
        </ProofOfPaymentProvider>
      </AuthProvider>
    </AppProvider>
  )
}
